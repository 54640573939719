
@font-face {
  font-family: 'Roboto', sans-serif;
  src : url(../assets/fonts/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2) format('woff2'),
        url(../assets/fonts/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2) format('woff2'),
        url(../assets/fonts/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');
  font-display: swap;
}

// Override Boostrap variables
// @import "bootstrap-variables";

// Import Bootstrap source files
// @import "bootstrap/bootstrap";

// // Custom styles
// @import "custom";

// //Custom Loader
// @import "spinners";

.back-btn{
  padding: 11px 20px !important;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  svg{
    height: auto;
    width: 24px;
    vertical-align: middle;
  }
}
.no-data{
	.icon{
			svg{
			width: 36px;
			height: auto;
		}
	}	
}
.dropdown .dropdown-menu .dropdown-item{
  display: flex !important;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  svg{
    width: 12px;
    height: auto;
    margin-left: 10px;
    vertical-align: text-top;
    margin-top: 2px;
  }
}
.dropdown .dropdown-menu .dropdown-item .up svg{
  transform: rotate(180deg);
}
.dropdown .st0, .edit_icon .st0, .top-toolbar .main-cta .nav-link .st0 {
  fill: #797979 !important;
}
.profile__avatar .editIcon svg{
  height: 23px;
  width: 23px;
  margin-top: 7px;
  .st0 {
    fill: #fff !important;
  }  
}
.edit-profile-info .btn svg{
  width: 18px;
  height: 18px;
}
.heightFix{
  min-height: 800px;
}

.pageLoaderUpdated {
	width: 100%;
	position: fixed;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  .update-notification {
    z-index: 99999999999999999;
    background-color:#d41d51;
    color: #fff !important;
    display:block;
    position: absolute;
    top:0;
    right:0;
    padding:0.8em 1.2em;
    text-decoration: none !important;
 }
 .offline-notification {
  background-color: #222;
  color: #fff;
  display:block;
  position: absolute;
  bottom:50;
  right:90;
  padding:0.8em 1.2em;
  text-decoration: none;
 }

.bell-icon {
  margin: 20px auto;
  text-align: center;
}
.bell-icon .reactSvg{
  width: 80px !important;
  display: inline-block !important;
}
.bell-icon h5{
  text-transform: uppercase;
}
.floating-button {
  z-index: 100;
  bottom: 12vh!important;
  right: 3.2vw !important;
  margin: 0 !important;
  .reactSvg {
    margin: auto;
    width: 35px;
  }
}
.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
@media screen and (max-width:980px){
  .fullscreen-notification-modal {
    min-width: 100%;
    margin: 0 !important;
  }

  .fullscreen-notification-modal .modal-content {
      min-height: 100vh;
  }

  .fullscreen-notification-modal .container {
    display: flex;
    min-height: 100vh;
  }
}